import React from 'react'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend } from 'chart.js'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import { ArrowDownUp } from 'lucide-react'
import Plus from '../../../../resources/plusindicator.png'
import Decrease from '../../../../resources/decrease.png'
import { investmentData } from '../../../../data/static'

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend)
const investment = {
  activities: [
    { time: 'Jan', amount: 50000 },
    { time: 'Feb', amount: 55000 },
    { time: 'Mar', amount: 50000 },
    { time: 'Apr', amount: 60000 },
    { time: 'May', amount: 58000 },
    { time: 'Jun', amount: 62000 },
    { time: 'Jul', amount: 65000 },
    { time: 'Aug', amount: 68000 },
    { time: 'Sep', amount: 70000 },
    { time: 'Oct', amount: 72000 },
    { time: 'Nov', amount: 75000 },
    { time: 'Dec', amount: 80000 }
  ]
}
// Line Chart Data
const chartData = {
  labels: investment.activities.map(activity => activity.time),
  datasets: [
    {
      label: 'Investment Growth (₦)',
      data: investment.activities.map(activity => activity.amount),
      borderColor: '#5CB85C',
      backgroundColor: '#ffffff',
      pointBackgroundColor: '#5CB85C',
      pointBorderColor: '#fff',
      pointRadius: 6,
      pointHoverRadius: 8,
      fill: true,
      tension: 0.3
    }
  ]
}
const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false },
    tooltip: {
      callbacks: {
        label: (context) => `₦${context.raw.toLocaleString()}`
      }
    }
  },
  scales: {
    x: {
      title: {
        display: false

      },
      grid: { display: false }
    },
    y: {
      beginAtZero: false,
      title: {
        display: false

      },
      ticks: {
        callback: (value) => `₦${value.toLocaleString()}`
      },
      grid: { display: false }
    }
  }
}

const UserInvestmentDetails = () => {
  return (
    <div className='w-full p-[2%] space-y-3'>
      {/* Back Button */}
      <div className='w-full'>
        <BackButton />
      </div>

      {/* Investment Details */}
      <div className='w-full flex lg:flex-row flex-col justify-between gap-8 items-start'>
        <div className='w-full lg:w-1/2 space-y-3'>
          <h1 className='font-[500] text-left text-black text-[14px] lg:text-[16px]'>
            {investmentData.title}
          </h1>
          <div className='w-full h-[280px]'>
            <img
              src={investmentData.imageUrl}
              alt='Investment'
              className='w-full h-full rounded-[8px]'
            />
          </div>

          {/* Balance and Units */}
          <div className='w-full  flex flex-col gap-3 pt-6'>
            <div className='w-full flex justify-between items-center font-[400] text-[14px] text-black'>
              <h1>Current balance</h1>
              <h1>Unit owned</h1>
            </div>
            <div className='w-full flex justify-between items-center font-[400] text-[14px] text-black'>
              <h1 className='font-[600] text-[18px] text-darkGreen'>{investmentData.balance}</h1>
              <h1>{investmentData.unitsOwned}</h1>
            </div>
          </div>
        </div>

        {/* Investment Info Section */}
        <div className='w-full lg:w-1/2'>
          <div className='flex flex-col gap-[20px] lg:gap-[10px] flex-[0.5]'>
            <p className='font-[500] text-[14px] lg:text-[16px] text-black'>
              Investment Information
            </p>
            <div className='gap-3 grid grid-cols-2'>
              {investmentData.investmentInfo.map((info, index) => (
                <div
                  key={index}
                  className='flex flex-col gap-2 border border-[#EEEEEE] rounded-lg flex-1 px-5 py-[15px] font-[600]'
                >
                  <p className='text-black text-[12px] lg:text-[14px]'>{info.label}</p>
                  <p className={`text-primary ts ${info.value === 'Active' && 'text-[#5CB85C]'}`}>
                    {info.value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Chart Wrapper */}
      <div className='w-full h-[332px] flex items-center justify-center rounded-lg'>
        <Line data={chartData} options={chartOptions} />
      </div>

      {/* Investment Activities Section */}
      <div className='w-full space-y-3'>
        <div className='w-full flex justify-between items-center'>
          <h1 className='text-[14px] lg:text-[16px] font-[500]'>Investment activities</h1>
          <h1 className='flex gap-1 text-[12px] lg:text-[14px] font-[400] justify-center items-center'>
            <ArrowDownUp size={15} /> Sort by
          </h1>
        </div>

        {/* Activities List */}
        {investmentData.activities.map((activity, index) => (
          <div
            key={index}
            className='w-full flex justify-between items-center border border-b-[#EEEEEE] pb-3 border-t-transparent border-r-transparent border-l-transparent'
          >
            <div className='w-[80%] lg:w-[40%] flex gap-4'>
              <div className={`w-[40px] h-[40px] shadow-sm ${activity.type === 'increase' ? 'bg-[#F5FBF5]' : 'bg-[#FDF2F3]'} center rounded-full`}>
                <img src={activity.type === 'increase' ? Plus : Decrease} alt='icon' />
              </div>
              <div className='flex flex-col'>
                <h1 className='font-[400] text-[12px] lg:text-[15px] text-[#444444]'>{activity.message}</h1>
                <h1 className='font-[400] text-[13px] text-[#666666]'>{activity.time}</h1>
              </div>
            </div>
            <h1 className={`text-[12px] lg:text-[16px] font-[500] ${activity.type === 'increase' ? 'text-[#5CB85C]' : 'text-[#D92534]'}`}>
              {activity.amount}
            </h1>
          </div>
        ))}
      </div>
    </div>
  )
}

export default UserInvestmentDetails
