import React, { useState } from 'react'
import './Index.css'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import useBackendService from '../../../../config/services/backend-service'
import { logout } from '../../../../config/store/reducers/authReducer'
import SideBar from '../SideBar/Sidebar'
import { LogOut, X } from 'lucide-react'
import NavBar from '../NavBar/NavBar'
import { RegularAdminDashboardContent } from '../../../data/static'
import MobileSidebar from '../MobileSideBar/MobileSideBar'

const RegularAppAdminLayoutWrapper = () => {
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { mutate } = useBackendService('/auth/logout', 'post', {
    onSuccess: () => {
      dispatch(logout())
      navigate('/')
    },
    onError: error => {
      console.error('Logout failed:', error)
    }
  })
  const handleLogout = () => {
    mutate()
  }
  const handleClick = () => {
    setVisible(true)
  }

  return (
    <div className='w-full flex max'>
      {visible === true && (
        <div className=' fixed h-full flex justify-end w-screen lg:hidden z-10 bg-black/30 left-0 top-0 '>
          {/* mobile view */}
          <section className=' bg-white w-[280px] flex flex-col items-start  h-screen py-8 gap-8 z-10 '>
            <div className=' w-full flex px-[20px] flex-row justify-between items-center '>
              <div className='' onClick={() => setVisible(false)}>
                <img
                  src={require('../../../resources/logoleaf.png')}
                  alt=''
                  className=' w-[98px] '
                />
              </div>
              <div className='' onClick={() => setVisible(false)}>
                <X size={20} />
              </div>
            </div>
            <div className=' w-full gap-5 flex flex-col '>
              {RegularAdminDashboardContent.map((menu, index) => (
                <MobileSidebar
                  key={menu.link}
                  name={menu.name}
                  icon={menu.icon}
                  link={menu.link}
                  orange={menu.orange}
                />
              ))}
            </div>
            <div className='flex-[1] justify-end flex flex-col p-3 items-end '>
              <div
                className=' text-red-400 gap-2 flex  ml-3'
                onClick={handleLogout}
              >
                <LogOut color='red' size={20} />
                Logout
              </div>
            </div>
          </section>
        </div>
      )}
      {/* deskop view */}
      <div className='sidebar-wrapper bg-darkGreen  overflow-y-scroll'>
        <div className='w-full flex flex-col justify-start items-center relative '>
          <div className='logo-cover px-10'>
            <img
              alt='logo'
              src={require('../../../resources/logo-white.png')}
              className='w-[142px]'
            />
          </div>
          <div className='mt-[20%] w-full flex flex-col gap-5 '>
            {RegularAdminDashboardContent.map(menu => (
              <SideBar
                key={menu.link}
                name={menu.name}
                icon={menu.icon}
                link={menu.link}
                orange={menu.orange}
              />
            ))}
          </div>
          <button className='logout-button-style bottom-[-14%] absolute' onClick={handleLogout}>
            <LogOut />
            Logout
          </button>
        </div>

      </div>

      <div className='topbar-wrapper '>
        <NavBar location={useLocation()} onClick={handleClick} />
        <div className=' w-full bg-gray-50'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default RegularAppAdminLayoutWrapper
