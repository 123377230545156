import React, { useEffect, useState } from 'react'
import useBackendService from '../../../../config/services/backend-service'
import InvestContainer from '../InvestContainer/InvestContainer'
import UserInvestments from './UserInvestments/UserInvestments'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'

const RegularInvest = ({ unlock = false }) => {
  const [data, setData] = useState([])
  const [activeTab, setActiveTab] = useState('opportunities')
  const [userInvestmentIds, setUserInvestmentIds] = useState([])
  const { mutate: fetchInvest, isLoading: investLoader } = useBackendService(
    '/investments?status=approved',
    'get',
    {
      onSuccess: (response) => {
        setData(response.data)
      },
      onError: (error) => {}
    }
  )
  const { mutate: fetchUserInvest, isLoading: userInvestmentLoader } = useBackendService(
    '/investments/users_investment',
    'get',
    {
      onSuccess: (response) => {
        const ids = response.data.map((investment) => investment.investment_id)
        setUserInvestmentIds(ids)
      },
      onError: (error) => {}
    }
  )

  useEffect(() => {
    fetchInvest()
    fetchUserInvest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderEmptyState = (message, description) => (
    <div className='text-center flex-col w-full h-full center mt-[10%]'>
      <div className='w-[150px] lg:w-[200px] center flex-col mx-auto'>
        <img
          alt='img'
          src={require('../../../resources/farmemptyimage.png')}
          className='w-full'
        />
      </div>
      <p className='ts font-semibold text-[#464665]'>{message}</p>
      <p className='ts text-[#464665] w-[70%] mx-auto'>{description}</p>
    </div>
  )

  const renderOpportunities = (items) => (
    <div className='grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-10 pt-4'>
      {items.map((item) => (
        <div className='lg:w-full w-[full]' key={item.id}>
          <InvestContainer item={item} unlock={unlock} />
        </div>
      ))}
    </div>
  )
  const renderYourInvestments = (items) => (
    <div className='grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-10 pt-4'>
      {items.map((item) => (
        <div className='lg:w-full w-[full]' key={item.id}>
          <UserInvestments item={item} />
        </div>
      ))}
    </div>
  )
  const userInvestmentsData = data.filter((item) =>
    userInvestmentIds.includes(item.id)
  )
  // Loading state
  if (investLoader || userInvestmentLoader) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <div className='w-full p-[2%] bg-white'>
      {/* Investment Tabs */}
      <div className='w-full flex justify-start items-center gap-2'>
        <button
          className={`rounded-lg p-3 ts border transition-all ${
            activeTab === 'opportunities'
              ? 'bg-darkGreen text-white '
              : 'bg-white text-[#888888] border-[#888888]'
          }`}
          onClick={() => setActiveTab('opportunities')}
        >
          Investment Opportunities
        </button>
        <button
          className={`rounded-lg p-3 ts border transition-all ${
            activeTab === 'yourInvestment'
              ? 'bg-darkGreen text-white '
              : 'bg-white text-[#888888] border-[#888888]'
          }`}
          onClick={() => setActiveTab('yourInvestment')}
        >
          Your Investment
        </button>
      </div>
      {/* Conditional Rendering for Content */}
      {activeTab === 'opportunities'
        ? data.length === 0
          ? renderEmptyState(
            'No investment yet',
            'Sorry, we do not have any investment for you at the moment..'
          )
          : renderOpportunities(data)
        : userInvestmentsData.length === 0
          ? renderEmptyState(
            'No active investments yet',
            'You would get an overview here once you invest in an opportunity.'
          )
          : renderYourInvestments(userInvestmentsData)}
    </div>
  )
}

export default RegularInvest
