import React from 'react'

const DashBoardHeader = ({
  title,
  desc,
  icon,
  btntext,
  link,
  parent,
  role
}) => {
  const showButton = parent !== undefined
    ? parent && role === 'administrator'
    : role === 'administrator'

  return (
    <div className='header-cover'>
      <div className='space-y-0 lg:space-y-1 hidden lg:flex-col lg:flex'>
        <h1 className='lg:text-3xl text-xl text-black'>{title}</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>{desc}</p>
      </div>
      {showButton && (
        <a href={link} className='add-farm'>
          {icon}
          {btntext}
        </a>
      )}
    </div>
  )
}

export default DashBoardHeader
