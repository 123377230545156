import React from 'react'
import { Link } from 'react-router-dom'

const UserInvestments = ({ item }) => {
  // Format price with commas
  const formattedPrice = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 0
  }).format(item.price)

  return (
    <Link to={`/invest/yourinvestment/${item.id}`} className='basic-investment-content-ctn w-full' key={item.id}>
      {/* Investment Image */}
      <div
        className='w-full h-[130px] lg:h-[200px] p-5 flex justify-end rounded-lg overflow-hidden bg-cover bg-center'
        style={{ backgroundImage: `url(${item.image_url})` }}
      >
        <div className={`investment capitalize ${item.status === 'active' && 'bg-[#EFF8EF] text-[#5CB85C]'} ${item.status === 'matured' && 'bg-[#F3F6F3] text-darkGreen'} ${item.status === 'closed' && 'bg-[#FDF4F5] text-[#D92534]'}`}>
          <p>{item.status}</p>
        </div>
      </div>

      {/* Title and Percent */}
      <div className='flex w-full justify-center flex-col items-start gap-2 mt-3'>
        <p className='text-black font-[600] w-full ts capitalize'>{item.title}</p>
        <div className='font-[600] text-black w-full ts'>
          <p>{formattedPrice}</p>
        </div>
      </div>

    </Link>
  )
}

export default UserInvestments
