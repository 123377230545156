import React from 'react'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import DynamicTextArea from '../../../SharedComponents/TextAreaInput/DynamicTextArea'
import Accordion from '../../../SharedComponents/Accordion/Accordion'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'
import { useDispatch, useSelector } from 'react-redux'
import { setProductCatalog } from '../../../../../config/store/reducers/productCatalog'
import ImageUpload from '../../../SharedComponents/ImageUpload/ImageUpload'

function Sales ({
  handlePrev, register, errors, isValid, isLoading, productCatalog, setImages,
  images
}) {
  const dispatch = useDispatch()
  const market = useSelector((state) => state.productCatalog.marketPrices)
  const store = useSelector((state) => state.productCatalog)
  const handleTextAreaChange = (e) => {
    const { name, value } = e.target
    dispatch(setProductCatalog({ [name]: value }))
  }
  const isFilled = store && images.length > 3
  return (
    <div className=' flex flex-col gap-5 lg:gap-10 max-w-fit  '>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='storageSolutions'
          register={register}
          errors={errors}
          text='Storage Solutions'
          value={productCatalog.storageSolutions}
          onChange={handleTextAreaChange}
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='packagingMethods'
          register={register}
          errors={errors}
          text='Packaging Methods'
          value={productCatalog.packagingMethods}
          onChange={handleTextAreaChange}
        />
      </div>

      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='logisticsProviders'
          register={register}
          errors={errors}
          text='Logistics Providers'
          value={productCatalog.logisticsProviders}
          onChange={handleTextAreaChange}
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='salesChannels'
          register={register}
          errors={errors}
          text='Sales Channels'
          value={productCatalog.salesChannels}
          onChange={handleTextAreaChange}
        />
      </div>
      <div className=' w-full lg:w-[876px] border border-[#f2f2f2] '>
        <div className=' p-4 lg:p-8 border-b border-[#f2f2f2] '>
          <Accordion
            text='Market Prices'
            subtext='Enter a minimum of 5 market prices'
          />
        </div>
        <div className=' p-4 lg:p-8 '>
          <DynamicTextArea
            name='marketPrices'
            register={register}
            errors={errors}
            text='Market Prices'
            isMarketPrice
            nextbtn='Add Another Market Price'
            storeValue={market}
          />
        </div>

      </div>
      <div className='w-full '>
        <ImageUpload
          name='productImages'
          images={images}
          setImages={setImages}
        />
      </div>
      <div className=' flex flex-row items-center gap-[15px] lg:gap-[30px] '>
        <div
          className={`w-full flex items-center justify-center  lg:w-[210px] h-[50px] ${
            isValid
              ? 'proceed-btn cursor-pointer bg-darkash text-primary border border-darkGreen'
              : 'rounded-[8px] bg-darkash text-primary border border-darkGreen '
          }`}
          onClick={handlePrev}
        >
          Previous
        </div>

        <div className='w-full lg:w-[210px]'>
          <GlobalButton
            title='Submit'
            isLoading={isLoading}
            isFilled={isFilled}
          />
        </div>

      </div>
    </div>
  )
}

export default Sales
