import { Plus } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../config/contexts/authProvider'
import useBackendService from '../../../../config/services/backend-service'
import Lock from '../../../resources/greenlock.png'
import Modal from '../../SharedComponents/Modal/Modal'
import DashBoardHeader from '../DashBoard/DashBoardHeader/DashBoardHeader'
import StaffDataTable from './StaffDataTable/StaffDataTable'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'

const StaffPage = () => {
  const [staffData, setStaffData] = useState([])
  const { user } = useAuth()
  const [isModalOpened, setModalOpened] = useState(false)

  const { mutate: fetchStaff, isLoading } = useBackendService(
    `/users?farm_id=${user.farmId}`,
    'get',
    {
      onSuccess: (response) => {
        setStaffData(response.data)
      },
      onError: (error) => {
        console.log(error)
      }
    }
  )

  useEffect(() => {
    if (!user.farmId) {
      setModalOpened(true)
    } else {
      fetchStaff()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  // Loading state
  if (isLoading) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <div className='farmwrapper'>
      <DashBoardHeader
        btntext='Add new staff'
        link='/staffs/new-staff'
        icon={<Plus size={20} />}
        role={user.role}
      />
      <StaffDataTable data={staffData} />

      <Modal
        title='Create a business to unlock management tools'
        desc='Set up your agri-business to access all the tools you need to manage, track and grow with ease.'
        welcomeicon={Lock}
        isOpen={isModalOpened}
        linkOverride='true'
        onClose={() => setModalOpened(false)}
      />
    </div>
  )
}

export default StaffPage
