import {
  Navigate,
  Route,
  Routes
  // Navigate
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'

import { useAuth } from './config/contexts/authProvider'
import ProtectedRoute from './config/contexts/protectedRoute'
import DashBoard from './farmapp/components/EnterpriseApp/DashBoard/DashBoard'
import AddBusinessBranch from './farmapp/components/EnterpriseApp/FarmPage/AddBusinessBranch/AddBusinessBranch'
import NoBusinessForm from './farmapp/components/EnterpriseApp/FarmPage/AddFarm/AddFarm'
import Farm from './farmapp/components/EnterpriseApp/FarmPage/FarmPage'
import LayoutWrapper from './farmapp/components/EnterpriseApp/LayOutWrapper/LayoutWrapper'
import OperationalCalendar from './farmapp/components/EnterpriseApp/OperationalCalendar/OperationalCalendar'
import OperationalCalendarCreate from './farmapp/components/EnterpriseApp/OperationalCalendar/OperationalCalendarCreate/OperationalCalendarCreate'
import OperationalCalendarDescription from './farmapp/components/EnterpriseApp/OperationalCalendar/OperationalCalendarDescription/OperationalCalendarDescription'
import AddReport from './farmapp/components/EnterpriseApp/Operations/AddNewOperation/AddReport'
import AddRequest from './farmapp/components/EnterpriseApp/Operations/AddNewOperation/AddRequest'
import ReportDetails from './farmapp/components/EnterpriseApp/Operations/OperationDetails/ReportDetails'
import RequestDetails from './farmapp/components/EnterpriseApp/Operations/OperationDetails/RequestDetails'
import OperationsPage from './farmapp/components/EnterpriseApp/Operations/OperationsPage'
import AddNewProduct from './farmapp/components/EnterpriseApp/ProductsPage/AddNewProduct/AddNewProduct'
import Product from './farmapp/components/EnterpriseApp/ProductsPage/ProductsPage'
import Profile from './farmapp/components/EnterpriseApp/ProfilePage/ProfilePage'
import AddNewStaffPage from './farmapp/components/EnterpriseApp/StaffPage/AddNewStaffPage/AddNewStaffPage'
import Staff from './farmapp/components/EnterpriseApp/StaffPage/StaffPage'
import Stock from './farmapp/components/EnterpriseApp/StockPage/StockPage'
import ForgotPasswordOtp from './farmapp/components/Login/UserForgotPasswordPage/ForgetPasswordOtp'
import ForgotPassword from './farmapp/components/Login/UserForgotPasswordPage/ForgotPassword'
import UserLoginPage from './farmapp/components/Login/UserLoginPage/UserLoginPage'
import NewsDescriptionPage from './farmapp/components/RegularApp/AgriNews/NewsDescriptionPage/NewsDescriptionPage'
import RegularAgriNews from './farmapp/components/RegularApp/AgriNews/RegularAgriNews'
import CatalogDescription from './farmapp/components/RegularApp/Catalog/CatalogDescription/CatalogDescription'
import RegularCatalog from './farmapp/components/RegularApp/Catalog/RegularCatalog'
import RegularHome from './farmapp/components/RegularApp/Home/RegularHome'
import InvestInformation from './farmapp/components/RegularApp/Invest/InvestInformation'
import RegularInvest from './farmapp/components/RegularApp/Invest/RegularInvest'
import RegularLayoutWrapper from './farmapp/components/RegularApp/LayoutWrapper/LayoutWrapper'
import RegularMap from './farmapp/components/RegularApp/Map/RegularMap'
import RegularSales from './farmapp/components/RegularApp/Sales/RegularSales'
import SalesDescription from './farmapp/components/RegularApp/Sales/SalesDescription/SalesDescription'
import BusinessApplications from './farmapp/components/RegularAppAdmin/BusinessApplications/BusinessApplications'
import BusinessDetailsPage from './farmapp/components/RegularAppAdmin/BusinessApplications/BusinessDetailsPage'
import NoBusinessDetailsPage from './farmapp/components/RegularAppAdmin/BusinessApplications/NoBusinessDetailsPage'
import AdminConsultaionDescription from './farmapp/components/RegularAppAdmin/Consultations/AdminConsultationDescription/AdminConsultationDescription'
import AdminConsultations from './farmapp/components/RegularAppAdmin/Consultations/AdminConsultations'
import AdminCreateReport from './farmapp/components/RegularAppAdmin/Consultations/AdminCreateReport/AdminCreateReport'
import AdminReportDescription from './farmapp/components/RegularAppAdmin/Consultations/AdminReportDescription/AdminReportDescription'
import AdminHome from './farmapp/components/RegularAppAdmin/Home/AdminHome'
import AddInvestments from './farmapp/components/RegularAppAdmin/Investments/AddInvestmentsPage/AddInvestments'
import AdminInvestments from './farmapp/components/RegularAppAdmin/Investments/AdminInvestments'
import InvestmentDetails from './farmapp/components/RegularAppAdmin/Investments/InvestmentDetails/InvestmentDetails'
import RegularAppAdminLayoutWrapper from './farmapp/components/RegularAppAdmin/LayoutWrapper/RegularAppAdminLayoutWrapper'
import AddNewArticle from './farmapp/components/RegularAppAdmin/News/AddNewArticle/AddNewArticle'
import AdminNews from './farmapp/components/RegularAppAdmin/News/AdminNews'
import NewsArticleDetails from './farmapp/components/RegularAppAdmin/News/NewsArticleDetails/NewsArticleDetails'
import AddNewProductCatalog from './farmapp/components/RegularAppAdmin/Products/AddNewProducts/AddNewProductCatalog'
import AdminProducts from './farmapp/components/RegularAppAdmin/Products/AdminProducts'
import ProductCatalogDetails from './farmapp/components/RegularAppAdmin/Products/ProductCatalogDetails/ProductCatalogDetails'
import AdminRequestDescription from './farmapp/components/RegularAppAdmin/Requests/AdminRequestDescription/AdminRequestDescription'
import AdminRequests from './farmapp/components/RegularAppAdmin/Requests/AdminRequests'
import AdminSettings from './farmapp/components/RegularAppAdmin/Settings/AdminSettings'
import AddTips from './farmapp/components/RegularAppAdmin/Tips/AddTips/AddTips'
import AdminTips from './farmapp/components/RegularAppAdmin/Tips/AdminTips'
import TipsDescription from './farmapp/components/RegularAppAdmin/Tips/TipsDescription/TipsDescription'
import AddNewUsers from './farmapp/components/RegularAppAdmin/Users/AddNewUsersPage/AddNewUsers'
import AdminUsers from './farmapp/components/RegularAppAdmin/Users/AdminUsers'
import UserDetails from './farmapp/components/RegularAppAdmin/Users/UserDetails/UserDetails'
import BasicPlan from './farmapp/components/SignUp/BasicPlanSignUp/BasicPlan'
import EnterprisePlan from './farmapp/components/SignUp/EnterprisePlanSignUp/EnterprisePlan'
import WelcomebackPage from './farmapp/components/SignUp/EnterprisePlanSignUp/EnterpriseWelcomeBackPage/WelcomebackPage'
import ProPlan from './farmapp/components/SignUp/ProPlanSignUp/ProPlan'
import ProPlanPayment from './farmapp/components/SignUp/ProPlanSignUp/ProPlanPayment'
import SignUpSelector from './farmapp/components/SignUp/SignUpSelector/SignUpSelector'
import UserCreatePasswordPage from './farmapp/components/SignUp/UserCreatePasswordPage/UserCreatePasswordPage'
import UserPasswordResetSuccess from './farmapp/components/SignUp/UserCreatePasswordPage/UserPasswordResetSuccess'
import AddNewStockPage from './farmapp/components/StockPage/AddNewStockPage/AddNewStockPage'
import StockDetailsPage from './farmapp/components/StockPage/StockDetailsPage/StockDetailsPage'
import About from './website/pages/About/About'
import GetInTouch from './website/pages/GetInTouch/GetInTouch'
import Home from './website/pages/Home/Home'
import Pricing from './website/pages/Pricing/Pricing'
import Products from './website/pages/Products/Products'
import UserInvestmentDetails from './farmapp/components/RegularApp/Invest/UserInvestments/UserInvestmentDetails'

function App () {
  const { isAuthenticated, user } = useAuth()

  let defaultRoute = '/dashboard'

  if (user && user.plan) {
    switch (user.plan) {
      case 'Enterprise':
        defaultRoute = user.role === 'administrator' ? '/dashboard' : '/stocks'
        break
      case 'Basic':
      case 'Pro':
        defaultRoute = '/home'
        break
      case 'Admin':
        defaultRoute = '/admin-home'
        break
      default:
        defaultRoute = '/home'
        break
    }
  }

  return (
    <>
      <ToastContainer
        position='top-right'
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route
          path='/'
          element={isAuthenticated ? <Navigate to={defaultRoute} /> : <Home />}
        />
        <Route
          path='/about'
          element={isAuthenticated ? <Navigate to={defaultRoute} /> : <About />}
        />
        <Route
          path='/pricing'
          element={
            isAuthenticated ? <Navigate to={defaultRoute} /> : <Pricing />
          }
        />
        <Route
          path='/products'
          element={
            isAuthenticated ? <Navigate to={defaultRoute} /> : <Products />
          }
        />
        <Route
          path='/getintouch'
          element={
            isAuthenticated ? <Navigate to={defaultRoute} /> : <GetInTouch />
          }
        />
        <Route
          path='/signup'
          element={
            isAuthenticated
              ? (
                <Navigate to={defaultRoute} />
                )
              : (
                <SignUpSelector />
                )
          }
        />
        <Route
          path='/signup/basicplan'
          element={
            isAuthenticated ? <Navigate to={defaultRoute} /> : <BasicPlan />
          }
        />
        <Route
          path='/signup/enterpriseplan'
          element={
            isAuthenticated
              ? (
                <Navigate to={defaultRoute} />
                )
              : (
                <EnterprisePlan />
                )
          }
        />
        <Route
          path='/signup/proplan'
          element={
            isAuthenticated
              ? (
                <Navigate to={defaultRoute} />
                )
              : (
                <ProPlanPayment />
                )
          }
        />
        <Route
          path='/signup/proplansignup'
          element={
            isAuthenticated ? <Navigate to={defaultRoute} /> : <ProPlan />
          }
        />
        <Route
          path='/welcomeback'
          element={
            isAuthenticated
              ? (
                <Navigate to={defaultRoute} />
                )
              : (
                <WelcomebackPage />
                )
          }
        />
        <Route
          path='/forgotpassword'
          element={
            isAuthenticated
              ? (
                <Navigate to={defaultRoute} />
                )
              : (
                <ForgotPassword />
                )
          }
        />
        <Route
          path='/forgotpassword/otp'
          element={
            isAuthenticated
              ? (
                <Navigate to={defaultRoute} />
                )
              : (
                <ForgotPasswordOtp />
                )
          }
        />
        <Route
          path='/createpassword'
          element={
            isAuthenticated
              ? (
                <Navigate to={defaultRoute} />
                )
              : (
                <UserCreatePasswordPage />
                )
          }
        />
        <Route
          path='/success'
          element={
            isAuthenticated
              ? (
                <Navigate to={defaultRoute} />
                )
              : (
                <UserPasswordResetSuccess />
                )
          }
        />
        <Route
          path='/login'
          element={
            isAuthenticated ? <Navigate to={defaultRoute} /> : <UserLoginPage />
          }
        />
        ;
        <Route
          path='/elink_verification'
          element={
            isAuthenticated
              ? (
                <Navigate to={defaultRoute} />
                )
              : (
                <UserPasswordResetSuccess
                  title='Account verified'
                  message='You have successfully verified your account. Kindly click the button below to take you to login page.'
                />
                )
          }
        />
        <Route element={<LayoutWrapper />}>
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route path='/dashboard' element={<DashBoard />} />
            <Route path='/businesses' element={<Farm />} />
            <Route path='/create-business' element={<NoBusinessForm />} />
            <Route path='/farm-products' element={<Product />} />
            <Route path='/stocks' element={<Stock />} />
            <Route path='/staffs' element={<Staff />} />
            <Route path='/staffs/new-staff' element={<AddNewStaffPage />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/farms/new-farm' element={<AddBusinessBranch />} />
            <Route path='/stocks/new-stock' element={<AddNewStockPage />} />
            <Route path='/operations' element={<OperationsPage />} />
            <Route path='/operations/report/:id' element={<ReportDetails />} />
            <Route
              path='/operations/request/:id'
              element={<RequestDetails />}
            />
            <Route path='/operations/add-report' element={<AddReport />} />
            <Route path='/operations/add-request' element={<AddRequest />} />
            <Route
              path='/businesses/new-business'
              element={<AddBusinessBranch />}
            />
            <Route path='/stocks/:id' element={<StockDetailsPage />} />
            <Route
              path='/operational-calendar'
              element={<OperationalCalendar />}
            />
            <Route
              path='/operational-calendar/:id'
              element={<OperationalCalendarDescription />}
            />
            <Route
              path='/operational-calendar/create-operational-calendar'
              element={<OperationalCalendarCreate />}
            />
            <Route
              path='/farmproducts/new-Product'
              element={<AddNewProduct />}
            />
            <Route path='/market/catalog' element={<RegularCatalog unlock />} />
            <Route
              path='/market/catalog/:id'
              element={<CatalogDescription unlock />}
            />
            <Route path='/market/invest' element={<RegularInvest unlock />} />
            <Route
              path='/market/investment/:id'
              element={<InvestInformation unlock />}
            />
            <Route
              path='/market/agrinews'
              element={<RegularAgriNews unlock />}
            />
            <Route
              path='/market/agrinews/:id'
              element={<NewsDescriptionPage unlock />}
            />
            <Route path='/market/sales' element={<RegularSales unlock />} />
            <Route
              path='/market/sales/:id'
              element={<SalesDescription unlock />}
            />
            <Route path='/market/map' element={<RegularMap unlock />} />
          </Route>
        </Route>
        {/* BasicDashboard Routes */}
        <Route element={<RegularLayoutWrapper />}>
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route path='/home' element={<RegularHome />} />
            <Route path='/catalog' element={<RegularCatalog />} />
            <Route path='/catalog/:id' element={<CatalogDescription />} />
            <Route path='/invest' element={<RegularInvest />} />
            <Route path='/investment/:id' element={<InvestInformation />} />
            <Route path='/invest/yourinvestment/:id' element={<UserInvestmentDetails />} />
            <Route path='/agrinews' element={<RegularAgriNews />} />
            <Route path='/agrinews/:id' element={<NewsDescriptionPage />} />
            <Route path='/sales' element={<RegularSales />} />
            <Route path='/sales/:id' element={<SalesDescription />} />
            <Route path='/map' element={<RegularMap />} />
            <Route path='/standard/profile' element={<Profile pro />} />

          </Route>
        </Route>
        {/* RegualarAppAdmin Routes */}
        <Route element={<RegularAppAdminLayoutWrapper />}>
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route path='/admin-home' element={<AdminHome />} />
            <Route path='/admin-investments' element={<AdminInvestments />} />
            <Route
              path='/admin-investments/:id'
              element={<InvestmentDetails />}
            />
            <Route
              path='/admin-investments/add-investment'
              element={<AddInvestments />}
            />
            <Route path='/admin-news' element={<AdminNews />} />
            <Route path='/admin-products' element={<AdminProducts />} />
            <Route
              path='/admin-products/:id'
              element={<ProductCatalogDetails />}
            />
            <Route
              path='/admin-products/add-products'
              element={<AddNewProductCatalog />}
            />
            <Route path='/admin-requests' element={<AdminRequests />} />
            <Route
              path='/admin-requests/:id'
              element={<AdminRequestDescription />}
            />
            <Route path='/admin-settings' element={<AdminSettings />} />
            <Route path='/admin-tips' element={<AdminTips />} />
            <Route
              path='/admin-consultations'
              element={<AdminConsultations />}
            />
            <Route
              path='/admin-create-report/:id'
              element={<AdminCreateReport />}
            />
            <Route
              path='/admin-consultations/:id'
              element={<AdminConsultaionDescription />}
            />
            <Route
              path='/report-description/:id'
              element={<AdminReportDescription />}
            />
            <Route path='/admin-tips/:id' element={<TipsDescription />} />
            <Route path='/admin-tips/add-tips' element={<AddTips />} />
            <Route path='/admin-users' element={<AdminUsers />} />
            <Route path='/admin-news/add-news' element={<AddNewArticle />} />
            <Route path='/admin-news/:id' element={<NewsArticleDetails />} />
            <Route path='/admin-users/add-user' element={<AddNewUsers />} />
            <Route path='/admin-users/:id' element={<UserDetails />} />
            <Route
              path='/admin-business-applications'
              element={<BusinessApplications />}
            />
            <Route
              path='/admin-business-applications/admin-business-details/:id'
              element={<BusinessDetailsPage />}
            />
            <Route
              path='/admin-business-applications/admin-nobusiness-details/:id'
              element={<NoBusinessDetailsPage />}
            />
          </Route>
        </Route>
      </Routes>
    </>
  )
}

export default App
