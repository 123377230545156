import {
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  Loader2,
  Timer
} from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'
import GlobalLoader from '../../../SharedComponents/GlobalLoader/GlobalLoader'
import Modal from '../../../SharedComponents/Modal/Modal'
import NoteComponent from '../../../SharedComponents/NoteComponent/NoteComponent'
import NavigvationSteps from '../NavigationSteps/NavigvationSteps'
import Catalog from './Catalog'
import Products from './Products'
import Sales from './Sales'

function ProductCatalogDetails () {
  const { id } = useParams()
  const { user } = useAuth()
  const [stage, setStage] = useState(1)
  const [images, setImages] = useState([])
  const [location, setLocation] = useState([])
  const [data, setData] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })
  const [careValue, setCareValue] = useState([{ value: '', date: '' }])
  const [tipsValue, setTipsValue] = useState([{ value: '', date: '' }])
  const handleNextStage = () => {
    if (stage < 3) {
      setStage(stage + 1)
    }
  }
  const handlePrevStage = () => {
    if (stage > 1) {
      setStage(stage - 1)
    } else setStage(1)
  }
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors }
  } = useForm({ mode: 'onChange' })
  const processValue = value => {
    try {
      const parsedValue = JSON.parse(value)
      if (Array.isArray(parsedValue)) {
        return parsedValue.length === 1 ? parsedValue[0] : parsedValue
      }
      return value
    } catch (error) {
      return value
    }
  }
  const { mutate: fetchCatalogue, isLoading } = useBackendService(
    `/product_catalogues/${id}`,
    'get',
    {
      onSuccess: (response) => {
        setData(response.data)
        setValue('name', response.data.name)
        setValue('category', response.data.type)
        setValue('description', response.data.description)
        setValue(
          'nutritionMarketValue',
          processValue(response.data.nutrition_market_value)
        )
        setValue('growingTips', processValue(response.data.growing_tips))
        setValue(
          'careInstructions',
          processValue(response.data.care_instructions)
        )
        setValue('storageSolutions', response.data.storage_solutions)
        setValue('packagingMethods', response.data.packaging_methods)
        setValue('marketPrices', response.data.market_prices)
        setValue('logisticsProviders', response.data.logistic_providers)
        setValue('salesChannels', response.data.sales_channels)

        if (response.data.location) {
          const locationArray = response.data.location.map(
            (loc) => loc.location
          )
          setLocation(locationArray)
        }
        if (response.data.images) {
          const imageArray = response.data.images.map((loc) => loc.image_url)
          setImages(imageArray)
        }
      },
      onError: (error) => {
        console.error('Failed to fetch Catalogue data:', error)
        toast.error('Failed to fetch Catalogue data')
      }
    }
  )

  useEffect(() => {
    fetchCatalogue()
  }, [fetchCatalogue])
  const { mutate: updateCatalogue, isLoading: updateLoader } =
    useBackendService(`/product_catalogues/${id}`, 'put', {
      onSuccess: () => {
        setModal({
          title: 'Catalogue updated',
          desc: 'Well done , you have successfully updated a catalogue',
          type: 'success',
          link: '/admin-products',
          visible: true
        })
        setModalOpen(true)
      },
      onError: (error) => {
        setModal({
          type: 'error',
          title: 'Submission failed',
          desc: 'You are unable to update the catalogue',
          visible: true
        })
        setModalOpen(true)
      }
    })

  // Loading state
  if (isLoading) {
    return <GlobalLoader />
  }
  const onSubmit = (data) => {
    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('category', data.category)
    formData.append('description', data.description)
    formData.append(
      'nutritionMarketValue',
      JSON.stringify(data.nutritionMarketValue)
    )
    formData.append('growingTips', JSON.stringify(tipsValue))
    formData.append('careInstructions', JSON.stringify(careValue))

    formData.append('storageSolutions', data.storageSolutions)
    formData.append('packagingMethods', data.packagingMethods)
    formData.append('marketPrices', JSON.stringify(data.marketPrices))
    formData.append('logisticsProviders', data.logisticsProviders)
    formData.append('salesChannels', data.salesChannels)
    location.forEach((location, index) => {
      formData.append(`locations[${index}]`, location)
    })
    images.forEach((image, index) => {
      formData.append('images', image.file)
    })
    updateCatalogue(formData)
  }
  const handleStatusChange = (status, e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('status', status)
    updateCatalogue(formData)
  }

  return (
    <div className=' flex lg:flex-row flex-col lg:gap-0 gap-3 px-3 lg:px-5 bg-white '>
      <div className=' flex flex-col flex-[0.7] lg:mr-3 lg:py-5 py-3 lg:gap-10 gap-5 '>
        <div className=' flex flex-col '>
          <a href='/admin-products'>
            <ArrowLeft size={24} />
          </a>
        </div>
        <div className='space-y-1 lg:space-y-2 '>
          <h1 className='lg:text-xl text-[12px] text-black'>Product Catalog</h1>
          <div className=' flex items-center gap-1.5 '>
            <div className=' hidden lg:flex '>
              <Timer size={18} />
            </div>
            <div className=' flex lg:hidden '>
              <Timer size={12} />
            </div>
            <p className=' text-grey font-[400] text-[12px] lg:text-[16px] '>
              Status:{' '}
              <span
                className={
                  data.status === 'approved'
                    ? 'text-green-500'
                    : 'text-[#F29339]'
                }
              >
                {data.status}
              </span>
            </p>
          </div>
        </div>

        <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
          <NavigvationSteps stage={stage} />
          {stage === 1 && (
            <Products
              register={register}
              errors={errors}
              control={control}
              setImages={setImages}
              images={images}
              location={location}
              setLocation={setLocation}
              user={user}
            />
          )}
          {stage === 2 && (
            <Catalog
              register={register}
              errors={errors}
              user={user}
              nutritionMarketValue={data.nutrition_market_value}
              growingTips={data.growing_tips}
              careInstructions={data.care_instructions}
              textAreas={careValue}
              setTextAreas={setCareValue}
              textAreass={tipsValue}
              setTextAreass={setTipsValue}
            />
          )}
          {stage === 3 && (
            <Sales
              register={register}
              errors={errors}
              user={user}
              marketPrices={data.market_prices}
            />
          )}
          <div className='flex mt-8 lg:mt-16 items-center justify-between  '>
            <div className=' flex flex-row gap-3 lg:gap-5 '>
              <div
                className=' size-10 rounded-full border cursor-pointer bg-[#F3F5F3] border-darkGreen flex items-center justify-center '
                onClick={handlePrevStage}
              >
                <ChevronLeft className=' text-darkGreen ' size={24} />
              </div>
              <div
                className=' size-10 rounded-full border cursor-pointer bg-[#F3F5F3] border-darkGreen flex items-center justify-center '
                onClick={handleNextStage}
              >
                <ChevronRight className=' text-darkGreen ' size={24} />
              </div>
            </div>

            {user.role === 'approver'
              ? (
                  data.status !== 'approved' && (
                    <div className=' flex gap-[30px] items-center '>
                      <button
                        onClick={(event) => handleStatusChange('rejected', event)}
                        className='  bg-[#FDF4F5] border border-[#D92534] cursor-pointer rounded-lg w-[210px] py-4  text-center '
                      >
                        <p className=' text-[#D92534] font-[500] text-[14px] '>
                          Reject
                        </p>
                      </button>
                      <button
                        onClick={(event) => handleStatusChange('approved', event)}
                        className='bg-darkGreen rounded-lg cursor-pointer py-4 w-[210px] text-center '
                      >
                        <p className=' text-white font-[500] text-[14px] '>
                          Approve
                        </p>
                      </button>
                    </div>
                  )
                )
              : (
                <button
                  type='submit'
                  className='w-[200px] font-[700] text-[12px] lg:text-[14px] cursor-pointer rounded-lg py-4 center bg-darkGreen text-white flex justify-center '
                  onClick={handleNextStage}
                  disabled={updateLoader}
                >
                  {updateLoader
                    ? (
                      <Loader2 className='w-6 h-6 lg:h-8 lg:w-8 animate-spin text-white' />
                      )
                    : (
                        'Submit Changes'
                      )}
                </button>
                )}
          </div>
        </form>
      </div>
      {(user.role === 'approver' || user.id === data.created_by) && (
        <div className='flex-[0.3]'>
          <NoteComponent data={data.id} table='product_catalogue' />
        </div>
      )}
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default ProductCatalogDetails
