import React, { useEffect, useState } from 'react'
import { Steps } from 'antd'
import { ChevronLeft } from 'lucide-react'
import BackButton from '../SharedComponents/BackButton/BackButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCurrentStep,
  setIsFirstNavigation
} from '../../../config/store/reducers/signUpReducer'

const NavigationSteps = ({
  First,
  Second,
  Third,
  onComplete,
  onAnswerSelect
}) => {
  const [submitted, setSubmitted] = useState(false)
  const [formData, setFormData] = useState({})
  const [sucess, setSucess] = useState(false)
  const [stepSize, setStepSize] = useState('small')
  const dispatch = useDispatch()
  const isFirstNavigation = useSelector(state => state.signUp.isFirstNavigation)
  const currentStep = useSelector(state => state.signUp.currentStep)

  // Use media query to set size
  useEffect(() => {
    const handleResize = () => {
      setStepSize(window.innerWidth < 768 ? 'small' : 'default')
    }

    handleResize() // Set initial size
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const next = () => {
    // Check if we're on the last step and should call onComplete
    if (currentStep === steps.length - 1 && onComplete) {
      onComplete() // Trigger the onComplete callback
      dispatch(setCurrentStep(0))
    } else {
      dispatch(setCurrentStep(currentStep + 1))
    }
  }
  const prev = () => {
    if (!isFirstNavigation && currentStep === 0) {
      dispatch(setIsFirstNavigation(true)) // Update state
    } else if (currentStep > 0) {
      // Navigate to the previous step if not on the first step
      dispatch(setCurrentStep(currentStep - 1))
    }
  }

  const steps = [
    {
      content: (
        <First
          next={next}
          setFormData={setFormData}
          onAnswerSelect={onAnswerSelect}
        />
      )
    },
    Second
      ? {
          content: (
            <Second
              next={next}
              setFormData={setFormData}
              formData={formData}
              onComplete={next}
            />
          )
        }
      : null,
    Third
      ? {
          content: (
            <Third
              setSucess={setSucess}
              sucess={sucess}
              submitted={submitted}
              setSubmitted={setSubmitted}
              formData={formData}
            />
          )
        }
      : null
  ].filter(Boolean)

  // Map steps to items for the Steps component
  const items = steps.map((item, index) => ({
    key: index, // Unique key for each step
    title: `Step ${index + 1}` // Placeholder title; adjust as needed
  }))

  const contentStyle = {
    marginTop: 16
  }
  // Dynamic width class based on step count
  const stepsWidthClass = steps.length === 2 ? 'w-[50%]' : 'w-[80%]'
  return (
    <div className='w-full  h-full overflow-y-scroll'>
      {submitted || sucess
        ? (
            ''
          )
        : (
          <div className='w-full flex justify-start items-center gap-4 '>
            {currentStep === 0 && isFirstNavigation === true
              ? (
                <BackButton />
                )
              : (
                <div className=' lg:w-[25%]'>
                  <ChevronLeft
                    className='text-darkgreen cursor-pointer'
                    onClick={prev}
                  />
                </div>
                )}

            <div className='flex justify-center  items-center w-full'>
              <Steps
                current={currentStep}
                items={items}
                direction='horizontal'
                responsive={false}
                className={`${stepsWidthClass} flex mx-auto horizontal-steps `}
                size={stepSize}
              />
            </div>
          </div>
          )}
      <div style={contentStyle}>{steps[currentStep].content}</div>
    </div>
  )
}

export default NavigationSteps
