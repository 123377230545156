import { format, startOfToday } from 'date-fns'
import { CalendarDays } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'
import Lock from '../../../../resources/greenlock.png'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import GlobalButton from '../../../SharedComponents/GlobalButton/GlobalButton'
import Modal from '../../../SharedComponents/Modal/Modal'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import SingleTextArea from '../../../SharedComponents/TextAreaInput/SingleTextArea'
import SingleTextInput from '../../../SharedComponents/TextInput/SingleTextInput'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import CalendarSelector from '../OperationalCalendarSelector/OperationalCalendarSelector'

const OperationalCalendarCreate = () => {
  const { user } = useAuth()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isModalOpened, setModalOpened] = useState(false)

  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })

  useEffect(() => {
    if (!user.farmId) {
      setModalOpened(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const today = startOfToday()
  const [selectedDay, setSelectedDay] = useState('')
  const [valueChainChoice, setValueChainChoice] = useState('')
  const formattedSelectedDay = selectedDay
    ? format(new Date(selectedDay), 'dd-MM-yyyy')
    : ''

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    watch // To track form changes dynamically
  } = useForm({ mode: 'onChange' })

  const { mutate: createCalendar, isLoading } = useBackendService(
    '/calendar',
    'post',
    {
      onSuccess: () => {
        setModal({
          type: 'success',
          title: 'Calendar submitted',
          desc: `Well done. You have successfully submitted a calendar. Please look out for your response on ${formattedSelectedDay}`,
          link: '/operational-calendar',
          visible: true
        })
        setModalOpen(true) // Open modal on success
      },
      onError: () => {
        setModal({
          type: 'error',
          title: 'Submission Failed',
          desc: 'There was an error submitting your calendar. Please try again.',
          visible: true
        })
        setModalOpen(true) // Open modal on error
      }
    }
  )

  const validateNoFields = () => {
    const values = getValues()
    const capital = values.capital
    const landSize = values.landsize

    if (isNaN(capital) || capital === '') {
      toast.error('Capital must be a numeric value.')
      return false
    }

    if (isNaN(landSize) || landSize === '') {
      toast.error('Land size must be a numeric value.')
      return false
    }

    return true
  }

  const onSubmit = async (data) => {
    if (valueChainChoice === 'No' && !validateNoFields()) return

    const final = { ...data, farmId: user.farmId }
    createCalendar(final)
  }

  const questionOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' }
  ]

  // const products = [
  //   { label: 'Yes', value: 'Yes' },
  //   { label: 'No', value: 'No' }
  // ]
  const status = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Completed', value: 'Completed' }
  ]

  // Watch form fields to enable button based on form state
  const watchedFields = watch()
  const isFormComplete =
    valueChainChoice === 'Yes'
      ? watchedFields.projectstatus &&
        watchedFields.challenges &&
        watchedFields.helpareas &&
        watchedFields.details &&
        watchedFields.product
      : watchedFields.location &&
        watchedFields.capital &&
        watchedFields.landsize &&
        watchedFields.date &&
        watchedFields.product
  const handleSave = () => {
    if (selectedDay) {
      setValue('date', formattedSelectedDay)
      console.log('Selected Date:', selectedDay)
    }
  }

  return (
    <div className='farmwrapper'>
      <BackButton />
      <div className='space-y-0 lg:space-y-1'>
        <h1 className='lg:text-3xl text-xl text-black'>Create calendar</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Fill in the details of your calendar
        </p>
      </div>

      <form className='form-wrapper' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col md:flex-row gap-5 md:gap-10 items-center'>
          <div className='w-full md:w-[50%] mt-1'>
            <SingleTextInput
              label='Product'
              name='product'
              placeholder='Input product'
              register={register}
              errors={errors}
              validationRules={{
                required: 'Product is required'
              }}
            />
          </div>

          <div className='w-full md:w-[50%]'>
            <SelectInput
              control={control}
              Controller={Controller}
              data={questionOptions}
              title='Have you gone into this value chain before?'
              name='valuechain'
              placeholder='Select answer'
              onChange={setValueChainChoice}
            />
          </div>
        </div>

        {/* Conditionally render input based on Yes/No selection */}
        {valueChainChoice === 'Yes' && (
          <>
            <div className='w-full md:w-[50%]'>
              <SelectInput
                control={control}
                Controller={Controller}
                data={status}
                title='Project status'
                name='projectstatus'
                placeholder='Select status'
              />
            </div>
            <div className='w-full'>
              <SingleTextArea
                label='Challenges encountered?'
                name='challenges'
                register={register}
                validation={{ required: 'Challenges are required' }}
                error={errors.challenges}
              />
            </div>
            <div className='w-full'>
              <SingleTextArea
                name='helpareas'
                validation={{ required: 'Helpareas are required' }}
                register={register}
                errors={errors.helpareas}
                label='Areas you need help?'
              />
            </div>
            <div className='w-full'>
              <SingleTextArea
                name='details'
                register={register}
                errors={errors.details}
                label='Any other details?'
                validation={{ required: 'Details are required' }}
              />
            </div>
          </>
        )}

        {valueChainChoice === 'No' && (
          <>
            <div className='flex flex-col md:flex-row gap-5 md:gap-10 items-center'>
              <div className='w-full md:w-[50%]'>
                <TextInput
                  name='location'
                  register={register}
                  errors={errors}
                  text='Location'
                  placeholder='Input here'
                />
              </div>

              <div className='w-full md:w-[50%]'>
                <TextInput
                  name='capital'
                  register={register}
                  errors={errors}
                  text='Capital'
                  placeholder='0.00'
                />
              </div>
            </div>
            <div className='flex flex-col md:flex-row gap-5 md:gap-10 items-center'>
              <div className='w-full md:w-[50%]'>
                <TextInput
                  name='landsize'
                  register={register}
                  errors={errors}
                  text='Land Size'
                  placeholder='Input here'
                />
              </div>

              <div className='w-full md:w-[50%]'>
                <TextInput
                  name='date'
                  register={register}
                  errors={errors}
                  text='Starting Date'
                  dvalue={formattedSelectedDay}
                  placeholder='Input here'
                  calendaricon={
                    <CalendarDays
                      size={20}
                      onClick={() =>
                        document.getElementById('calendar').showModal()
                      }
                    />
                  }
                />
              </div>
            </div>
          </>
        )}

        <div className='w-full md:w-[50%]'>
          <GlobalButton
            title='Create Calendar'
            isFilled={isFormComplete}
            isLoading={isLoading}
          />
        </div>
      </form>

      <CalendarSelector
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        today={today}
        onSave={handleSave}
      />

      {/* Conditionally render the modal */}
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}

      <Modal
        title='Create a business to unlock management tools'
        desc='Set up your agri-business to access all the tools you need to manage, track and grow with ease.'
        welcomeicon={Lock}
        isOpen={isModalOpened}
        linkOverride='true'
        onClose={() => setModalOpened(false)}
      />
    </div>
  )
}

export default OperationalCalendarCreate
