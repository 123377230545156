import React, { useEffect, useState } from 'react'
import TrendingNews from './TrendingNews/TrendingNews'
import ForYouSection from './ForYouSection/ForYouSection'
import useBackendService from '../../../../config/services/backend-service'
import GlobalLoader from '../../SharedComponents/GlobalLoader/GlobalLoader'

const RegularAgriNews = ({ unlock = false }) => {
  const [data, setData] = useState([])

  const { mutate: fetchNews, isLoading } = useBackendService(
    '/articles?status=approved',
    'get',
    {
      onSuccess: response => {
        setData(response.data)
      },
      onError: error => {
        console.error('Failed to fetch products:', error)
      }
    }
  )

  useEffect(() => {
    fetchNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const trendingNews = data.filter(item => item.category === 'trending')
  const notTrending = data.filter(item => item.category !== 'trending')
  // Loading state
  if (isLoading) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <div className='w-full bg-white p-[2%]'>
      {data.length > 0
        ? (
          <>
            {trendingNews.length > 0 && <TrendingNews data={data} unlock={unlock} />}
            {notTrending.length > 0 && <ForYouSection data={data} unlock={unlock} />}
          </>
          )
        : (
          <div className='text-center flex-col w-full h-full center mt-[10%]'>
            <div className='w-[150px] lg:w-[200px] center flex-col mx-auto'>
              <img
                alt='img'
                src={require('../../../resources/farmemptyimage.png')}
                className='w-full'
              />
            </div>
            <p className='ts font-semibold text-[#464665]'>No news yet</p>
            <p className='ts text-[#464665] w-[70%] mx-auto'>
              Sorry, we do not have any news to share with you at the moment.
            </p>
          </div>
          )}
    </div>
  )
}

export default RegularAgriNews
