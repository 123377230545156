import { Plus, X } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setProductCatalog } from '../../../../config/store/reducers/productCatalog'

const DynamicTextInput = ({ errors, text, register }) => {
  const dispatch = useDispatch()

  // Get the existing values from Redux store
  const existingCatalogs = useSelector(
    state => state.productCatalog.nutritionMarketValue
  )

  const [catalogs, setCatalogs] = useState(
    existingCatalogs.length > 0 ? existingCatalogs : [{ title: '', value: '' }]
  )

  // Update state whenever the store changes
  useEffect(() => {
    setCatalogs(existingCatalogs.length > 0 ? existingCatalogs : [{ title: '', value: '' }])
  }, [existingCatalogs])

  const handleCatalogChange = (index, field, fieldValue) => {
    const updatedCatalogs = catalogs.map((catalog, i) =>
      i === index ? { ...catalog, [field]: fieldValue } : catalog
    )
    setCatalogs(updatedCatalogs)
    dispatch(setProductCatalog({ nutritionMarketValue: updatedCatalogs })) // Update Redux store
  }

  const addCatalog = () => {
    const updatedCatalogs = [...catalogs, { title: '', value: '' }]
    setCatalogs(updatedCatalogs)
    dispatch(setProductCatalog({ nutritionMarketValue: updatedCatalogs })) // Update Redux store
  }

  const removeCatalog = index => {
    const updatedCatalogs = catalogs.filter((_, i) => i !== index)
    setCatalogs(updatedCatalogs)
    dispatch(setProductCatalog({ nutritionMarketValue: updatedCatalogs })) // Update Redux store
  }

  return (
    <div className='flex flex-col gap-4 lg:gap-8'>
      {catalogs.map((catalog, index) => (
        <div key={index}>
          <div className='flex w-full items-center gap-1.5'>
            <div className='flex flex-col flex-[0.4] gap-1'>
              <label htmlFor={`title-${index}`}>Title</label>
              <input
                type='text'
                name={`catalogs[${index}].title`}
                placeholder='Enter title'
                value={catalog.title}
                {...register(`nutritionMarketValue.${index}.title`)}
                onChange={e =>
                  handleCatalogChange(index, 'title', e.target.value)}
                className='email-input'
              />
            </div>
            <div className='flex flex-col flex-[0.6] gap-1'>
              <label htmlFor={`value-${index}`}>Value</label>
              <input
                type='text'
                name={`catalogs[${index}].value`}
                placeholder='Enter value'
                value={catalog.value}
                {...register(`nutritionMarketValue.${index}.value`)}
                onChange={e =>
                  handleCatalogChange(index, 'value', e.target.value)}
                className='email-input'
              />
            </div>
          </div>
          {index > 0 && (
            <div className='flex justify-end items-center gap-2.5 mt-1'>
              <X className='text-red-300 text-[10px]' size={14} />
              <div
                className='text-red-300 text-[12px] cursor-pointer'
                onClick={() => removeCatalog(index)}
              >
                Remove
              </div>
            </div>
          )}
        </div>
      ))}
      <div className='items-center flex gap-1'>
        <Plus className='text-darkGreen' />
        <div
          onClick={addCatalog}
          className='text-[12px] text-darkGreen lg:text-[14px] cursor-pointer'
        >
          Add another
        </div>
      </div>
      {errors[text] && <p className='error-text'>{errors[text].message}</p>}
    </div>
  )
}

export default DynamicTextInput
