import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import React, { useState } from 'react'
import DynamicTextInput from '../../../SharedComponents/TextInput/DynamicTextInput'
import Accordion from '../../../SharedComponents/Accordion/Accordion'
import DynamicTextArea from '../../../SharedComponents/TextAreaInput/ProductDetailsTextArea'

function Catalog ({
  errors,
  register,
  user,
  nutritionMarketValue,
  growingTips,
  careInstructions,
  textAreas,
  setTextAreas,
  textAreass,
  setTextAreass
}) {
  const [isMarketVisible, setIsMarketVisible] = useState(false)
  const [isGrowthVisible, setIsGrowthVisible] = useState(false)
  const [careVisible, setcareVisible] = useState(false)

  const handleMarketChange = () => {
    setIsMarketVisible(prev => !prev)
  }
  const handleTipsChange = () => {
    setcareVisible(prev => !prev)
  }
  const handleGrowthChange = () => {
    setIsGrowthVisible(prev => !prev)
  }
  return (
    <div className='flex flex-col gap-4 lg:gap-10 '>
      <form className=' flex flex-col lg:gap-10 gap-2'>
        <div className=' w-full  flex items-center flex-col lg:flex-row gap-5 lg:gap-[60px] '>
          <TextArea
            name='description'
            text='Description'
            placeholder='Enter a Description'
            register={register}
            errors={errors}
            disabled={user.role === 'approver'}
          />
        </div>
      </form>
      <div className=' w-full rounded-lg flex flex-col  border border-[#f2f2f2] '>
        <div className=' p-4 lg:p-8 border-b border-[#f2f2f2] '>
          <Accordion
            text='Add nutritional/market value'
            subtext='Enlighten users on the value'
            flipped={isMarketVisible}
            onClick={handleMarketChange}
          />
        </div>
        {isMarketVisible && (
          <div className=' p-4 lg:p-8 '>
            <DynamicTextInput
              errors={errors}
              text='Market Tips'
              register={register}
              nextbtn='Add another'
              existingCatalogs={nutritionMarketValue}
            />
          </div>
        )}
      </div>
      <div className=' w-full rounded-lg flex flex-col  border border-[#f2f2f2] '>
        <div className=' p-4 lg:p-8 border-b border-[#f2f2f2] '>
          <Accordion
            text='Add growing tips'
            subtext='Enlighten users on the value'
            flipped={isGrowthVisible}
            onClick={handleGrowthChange}
          />
        </div>
        {isGrowthVisible && (
          <div className='w-full  p-4 lg:p-8 '>
            <DynamicTextArea
              errors={errors}
              text='Growth Tips'
              register={register}
              nextbtn='Add more tips'
              existingTextAreas={growingTips}
              textAreas={textAreass}
              setTextAreas={setTextAreass}
            />
          </div>
        )}
      </div>
      <div className=' w-full rounded-lg flex flex-col  border border-[#f2f2f2] '>
        <div className=' p-4 lg:p-8 border-b border-[#f2f2f2] '>
          <Accordion
            text='Add care instruction'
            subtext='Enlighten users on the value'
            flipped={careVisible}
            onClick={handleTipsChange}
          />
        </div>
        {careVisible && (
          <div className='w-full  p-4 lg:p-8 '>
            <DynamicTextArea
              errors={errors}
              text='Care Instructions'
              register={register}
              nextbtn='Add more instructions'
              existingTextAreas={careInstructions}
              textAreas={textAreas}
              setTextAreas={setTextAreas}
            />

          </div>
        )}
      </div>
    </div>
  )
}

export default Catalog
