import { LogOut, User, X } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import useBackendService from '../../../../config/services/backend-service'
import { logout } from '../../../../config/store/reducers/authReducer'
import GreenProductIcon from '../../../resources/greenproducticon.png'
import GreenHomeIcon from '../../../resources/home.png'
import Logo from '../../../resources/logo-white.png'
import ManageGreen from '../../../resources/managegreen.png'
import Manage from '../../../resources/management.png'
import OperationGreen from '../../../resources/operationgreen.png'
import OperationWhite from '../../../resources/operationwhite.png'
import Products from '../../../resources/product.png'
import DashboardIcon from '../../../resources/whitedashboard.png'
import { useAuth } from '../.././../../config/contexts/authProvider'
import ChatInterCom from '../../SharedComponents/ChatInterCom/ChatInterCom'
import MobileSidebar from '../MobileSideBar/MobileSideBar'
import TopNav from '../NavBar/TopNav'
import Sidebar from '../SideBar/Sidebar'
import './index.css'

const LayoutWrapper = () => {
  const [visible, setVisible] = useState(false)
  const [isManagementOpen, setIsManagementOpen] = useState(false)
  const [isMarketOpen, setIsMarketOpen] = useState(false)
  const [isAgricOpen, setIsAgricOpen] = useState(false)
  const { user } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { mutate } = useBackendService('/auth/logout', 'post', {
    onSuccess: () => {
      dispatch(logout())
      navigate('/')
    },
    onError: (error) => {
      console.error('Logout failed:', error)
    }
  })

  const handleLogout = () => {
    mutate()
  }

  const handleClick = () => {
    setVisible(true)
  }

  const menus = [
    ...(user.role === 'administrator'
      ? [
          {
            name: 'Dashboard',
            icon: DashboardIcon,
            orange: GreenHomeIcon,
            link: '/dashboard'
          }
        ]
      : []),
    {
      name: 'Catalogue',
      icon: require('../../../resources/bookwhite.png'),
      orange: require('../../../resources/bookgreen.png'),
      link: '/market/catalog'
    },
    {
      name: 'Agri-news',
      icon: require('../../../resources/agricnewswhite.png'),
      orange: require('../../../resources/agricnewsgreen.png'),
      link: '/market/agrinews'
    },
    {
      name: 'Management',
      icon: Manage,
      orange: ManageGreen,
      submenu: [
        {
          name: 'Businesses',
          link: '/businesses'
        },
        {
          name: 'Products',
          link: '/farm-products'
        },

        {
          name: 'Stock',
          link: '/stocks'
        },
        ...(user.role === 'administrator'
          ? [
              {
                name: 'Staffs',
                link: '/staffs'
              }
            ]
          : []),
        {
          name: 'Activities',
          link: '/operations'
        }
      ]
    },
    {
      name: 'Operations',
      icon: OperationWhite,
      orange: OperationGreen,
      submenu: [
        {
          name: 'Calendar',
          link: '/operational-calendar'
        },
        {
          name: 'Map',
          link: '/market/map'
        }
      ]
    },

    {
      name: 'Agribusiness',
      icon: Products,
      orange: GreenProductIcon,
      submenu: [
        ...(user.role === 'administrator'
          ? [
              {
                name: 'Invest',
                link: '/market/invest'
              }
            ]
          : []),

        {
          name: 'Sales',
          link: '/market/sales'
        }
      ]
    }
  ]

  useEffect(() => {
    const managementLinks = [
      '/businesses',
      '/farm-products',
      '/stocks',
      '/staffs',
      '/operations'
    ]
    const operationLinks = ['/operational-calendar', '/market/map']
    const agricLinks = ['/market/invest', '/market/sales']

    if (managementLinks.includes(location.pathname)) {
      setIsManagementOpen(true)
    }

    if (operationLinks.includes(location.pathname)) {
      setIsMarketOpen(true)
    }
    if (agricLinks.includes(location.pathname)) {
      setIsAgricOpen(true)
    }
  }, [location.pathname])

  const toggleManagement = () => {
    setIsManagementOpen((prevState) => !prevState)
    setIsMarketOpen(false)
    setIsAgricOpen(false)
  }

  const toggleMarket = () => {
    setIsMarketOpen((prevState) => !prevState)
    setIsManagementOpen(false)
    setIsAgricOpen(false)
  }
  const toggleAgric = () => {
    setIsAgricOpen((prevState) => !prevState)
    setIsManagementOpen(false)
    setIsMarketOpen(false)
  }

  return (
    <div className='w-full h-screen-adjusted flex max overflow-y-scroll'>
      {visible && (
        <div className='fixed h-full flex justify-end w-screen lg:hidden z-[1000] bg-black/30 left-0 top-0'>
          <section className='bg-white overflow-y-scroll w-[280px] flex flex-col items-start h-screen py-8 gap-8 z-10'>
            <div className='w-full flex px-[20px] flex-row justify-between items-center'>
              <div className='' onClick={() => setVisible(false)}>
                <img
                  src={require('../../../resources/logoleaf.png')}
                  alt=''
                  className='w-[98px]'
                />
              </div>
              <div className='' onClick={() => setVisible(false)}>
                <X size={20} />
              </div>
            </div>
            <div className='w-full flex flex-col justify-between h-[880px]  '>
              <div className='w-full gap-5 flex flex-col'>
                {menus.map((menu, index) => (
                  <div key={menu.link}>
                    <div
                      className='flex justify-between items-center cursor-pointer'
                      onClick={
                        menu.name === 'Management'
                          ? toggleManagement
                          : menu.name === 'Operations'
                          ? toggleMarket
                          : menu.name === 'Agribusiness'
                          ? toggleAgric
                          : undefined
                      }
                    >
                      <MobileSidebar
                        name={menu.name}
                        icon={menu.icon}
                        link={menu.link}
                        orange={menu.orange}
                      />
                    </div>

                    {menu.submenu && (
                      <div
                        className={`submenu transition-all ease-in-out duration-300 overflow-hidden ${
                          menu.name === 'Management' && isManagementOpen
                            ? 'max-h-screen opacity-100'
                            : menu.name === 'Operations' && isMarketOpen
                            ? 'max-h-screen opacity-100'
                            : menu.name === 'Agribusiness' && isAgricOpen
                            ? 'max-h-screen opacity-100'
                            : 'max-h-0 opacity-0'
                        }`}
                      >
                        {menu.submenu.map((subMenu, idx) => (
                          <MobileSidebar
                            key={subMenu.link}
                            name={subMenu.name}
                            link={subMenu.link}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className='w-full  justify-end flex flex-col p-3 items-start gap-3'>
                <Link className='text-darkgreen gap-2 flex ml-3' to='/profile'>
                  <User className='text-darkgreen' size={25} />
                  Profile
                </Link>
                <div
                  className='text-red-400 gap-2 flex ml-3'
                  onClick={handleLogout}
                >
                  <LogOut color='red' size={20} />
                  Logout
                </div>
              </div>
            </div>
          </section>
        </div>
      )}

      {/* desktop view sidebar */}
      <div className='sidebar-wrapper bg-darkGreen overflow-y-scroll'>
        <div className='top-content-wrapper'>
          <div className='logo-cover pl-[50px]'>
            <img alt='logo' src={Logo} className='w-[80px] lg:w-[142px]' />
          </div>
          <div className='mt-[20%] w-full  h-[85vh] '>
            {menus.map((menu) => (
              <div key={menu.link}>
                <div
                  className='flex justify-between items-center cursor-pointer'
                  onClick={
                    menu.name === 'Management'
                      ? toggleManagement
                      : menu.name === 'Operations'
                      ? toggleMarket
                      : menu.name === 'Agribusiness'
                      ? toggleAgric
                      : undefined
                  }
                >
                  <Sidebar
                    name={menu.name}
                    icon={menu.icon}
                    link={menu.link}
                    orange={menu.orange}
                    isManagementOpen={isManagementOpen}
                    isMarketOpen={isMarketOpen}
                    isAgricOpen={isAgricOpen}
                    submenu={menu.submenu || []}
                  />
                </div>

                {menu.submenu && (
                  <div
                    className={`submenu transition-all ease-in-out duration-300 overflow-hidden ${
                      menu.name === 'Management' && isManagementOpen
                        ? 'max-h-screen opacity-100'
                        : menu.name === 'Operations' && isMarketOpen
                        ? 'max-h-screen opacity-100'
                        : menu.name === 'Agribusiness' && isAgricOpen
                        ? 'max-h-screen opacity-100'
                        : 'max-h-0 opacity-0'
                    }`}
                  >
                    {menu.submenu.map((subMenu) => (
                      <Sidebar
                        key={subMenu.name}
                        name={subMenu.name}
                        link={subMenu.link}
                        isSubMenu
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
            <div className='w-full justify-start flex flex-col lg:mb-14 xl:mb-2 items-start pt-[80%]  '>
              <Link to='/profile' className='logout-button-style ts'>
                <User />
                Profile
              </Link>
              <button className='logout-button-style ts' onClick={handleLogout}>
                <LogOut />
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='topbar-wrapper'>
        <TopNav onClick={handleClick} location={useLocation()} menus={menus} />
        <div className='w-full'>
          <Outlet />
        </div>
      </div>

      {/* botchat */}
      <ChatInterCom />
    </div>
  )
}

export default LayoutWrapper
