import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../../config/contexts/authProvider'
import PersonalInformation from './PersonalInformation/PersonalInformation'
import ProfileCardDetails from './ProfileCardDetails/ProfileCardDetails'
import ProfileChangePassword from './ProfileChangePassword/ProfileChangePassword'
import ProfileHelpCenter from './ProfileHelpCenter/ProfileHelpCenter'

const ProfilePage = ({ pro = false }) => {
  const [selectedItem, setSelectedItem] = useState('My Account')
  const { isParentFarm, user } = useAuth()
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    if (params.get('bank') === 'true') {
      if (user?.plan === 'Enterprise') {
        if (isParentFarm && user?.role === 'administrator') {
          setSelectedItem('Bank')
        }
      }
    } else if (params.get('help') === 'true') {
      setSelectedItem('Help center')
    }
  }, [location, isParentFarm, user])

  const handleSelection = (item) => {
    if (item === 'Bank') {
      if (user?.plan === 'Enterprise') {
        if (isParentFarm && user?.role === 'administrator') {
          setSelectedItem('Bank')
        } else {
          setSelectedItem('My Account')
        }
      } else {
        setSelectedItem('Bank')
      }
    } else {
      setSelectedItem(item)
    }
  }

  const renderContent = () => {
    switch (selectedItem) {
      case 'My Account':
        return <PersonalInformation />
      case 'Bank':
        return <ProfileCardDetails />
      case 'Password':
        return <ProfileChangePassword pro={pro} />
      case 'Help center':
        return <ProfileHelpCenter />
      default:
        return <div>Select an option to see the content</div>
    }
  }

  const getItemClass = (item) => {
    return item === selectedItem ? 'text-black font-semibold' : 'text-gray-400'
  }

  return (
    <div className='w-full h-full p-[2%]'>
      <h1 className='font-semibold text-lg'>Profile</h1>
      <div className='w-full h-full flex justify-center md:flex-row flex-col items-start gap-2 pt-4'>
        {/* Left Panel */}
        <div className='w-full md:w-[30%] border border-gray-300 rounded-md space-y-4 p-4'>
          <h1
            onClick={() => handleSelection('My Account')}
            className={`cursor-pointer ${getItemClass('My Account')}`}
          >
            My Account
          </h1>

          {/* Conditional Rendering for 'Bank' */}
          {user?.plan === 'Enterprise' ? (
            isParentFarm &&
            user?.role === 'administrator' && (
              <h1
                onClick={() => handleSelection('Bank')}
                className={`cursor-pointer ${getItemClass('Bank')}`}
              >
                Bank
              </h1>
            )
          ) : (
            <h1
              onClick={() => handleSelection('Bank')}
              className={`cursor-pointer ${getItemClass('Bank')}`}
            >
              Bank
            </h1>
          )}

          <h1
            onClick={() => handleSelection('Password')}
            className={`cursor-pointer ${getItemClass('Password')}`}
          >
            Password
          </h1>
          <h1
            onClick={() => handleSelection('Help center')}
            className={`cursor-pointer ${getItemClass('Help center')}`}
          >
            Help center
          </h1>
        </div>

        {/* Right Panel */}
        <div className='w-full md:w-[70%]'>{renderContent()}</div>
      </div>
    </div>
  )
}

export default ProfilePage
