import React, { useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import useBackendService from '../../../../config/services/backend-service'
import { setLandDetails } from '../../../../config/store/reducers/signUpReducer'
import GlobalButton from '../../SharedComponents/GlobalButton/GlobalButton'
import SelectInput from '../../SharedComponents/SelectInput/SelectInput'
import SingleTextArea from '../../SharedComponents/TextAreaInput/SingleTextArea'
import SingleTextInput from '../../SharedComponents/TextInput/SingleTextInput'
import EnterpriseSignUpConfirmation from './EnterpriseSignUpConfirmation'

const EnterpriseExisitingLandForm = () => {
  const dispatch = useDispatch()
  const { landDetails, businessDetails, enterpriseUser } = useSelector(
    (state) => state.signUp
  )

  const landQuestion = landDetails ? landDetails.question : ''
  const landSize = landDetails ? landDetails.landsize : ''
  const agriResources = landDetails ? landDetails.agricresources : ''
  const resourcesList = landDetails ? landDetails.resourceslist : ''
  const resourcesQuestion = landDetails ? landDetails.resourcesquestion : ''
  const info = landDetails ? landDetails.info : ''
  const information = landDetails ? landDetails.information : ''
  const enterpriseBudget = businessDetails ? businessDetails.budget : ''
  const enterpriseLocationChoice = businessDetails
    ? businessDetails.locationchoice
    : ''
  const enterpriseVenture = businessDetails ? businessDetails.venture : ''
  const enterpriseBusinessType = businessDetails
    ? businessDetails.businesstype
    : ''
  const [success, setSuccess] = useState(false)

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      question: landQuestion,
      landsize: landSize,
      agricresources: agriResources,
      resourceslist: resourcesList,
      resourcesquestion: resourcesQuestion,
      info,
      information
    }
  })

  const question = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]
  const resourcesquestion = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]

  const selectedAnswer = watch('question')
  const noResourcesQuestion = watch('resourcesquestion')
  const noInformation = watch('information')
  const noValidInput = noInformation && noResourcesQuestion

  const { mutate, isLoading } = useBackendService('/auth/farm', 'post', {
    onSuccess: (data) => {
      setSuccess(true)
    },
    onError: (error) => {
      if (error?.response?.data?.message === 'Email already exists') {
        toast.error('This email is already registered. Please use another one.')
      } else {
        toast.error('Something went wrong. Please try again.')
      }
    }
  })
  const landsize = watch('landsize')
  const list = watch('resourceslist')
  const infor = watch('info')

  const onSubmit = async (data) => {
    let filteredData

    if (selectedAnswer === 'Yes') {
      const { question, landsize, agricresources, resourceslist, info } = data
      filteredData = { question, landsize, agricresources, resourceslist, info }
    } else if (selectedAnswer === 'No') {
      const { question, resourcesquestion, information } = data
      filteredData = { question, resourcesquestion, information }
    }
    // Dispatch the form data to Redux store
    dispatch(setLandDetails({ ...filteredData }))

    const payload = {
      email: enterpriseUser?.email,
      isParentFarm: true,
      additionalData: {
        existing_land: landQuestion,
        land_size: landSize,
        agri_resources: agriResources,
        resources_list: resourcesList,
        resources_question: resourcesQuestion,
        info,
        information,
        enterprise_budget: enterpriseBudget,
        enterprise_location_choice: enterpriseLocationChoice,
        enterprise_venture: enterpriseVenture,
        enterprise_business_type: enterpriseBusinessType
      }
    }
    mutate(payload)
    console.log('Filtered data:', filteredData)
  }
  const hasResources = useWatch({ control, name: 'agricresources' })

  const yesValidInput =
    hasResources === 'No' ? landsize && infor : landsize && infor && list

  return (
    <div className='w-full h-[90vh] lg:h-full'>
      {success ? (
        <EnterpriseSignUpConfirmation />
      ) : (
        <>
          <h1 className='header-text'>Business Details</h1>
          <p className='ts text-[#666666]'>
            This details would help curate a personalized experience for you.
          </p>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className='w-full space-y-4 mt-6'
          >
            <SelectInput
              control={control}
              Controller={Controller}
              data={question}
              title='Do you have existing land for agribusiness?'
              name='question'
              placeholder='Select answer'
            />

            {selectedAnswer === 'Yes' && (
              <div className='w-full space-y-6'>
                <SingleTextInput
                  label='What is the size of the land?'
                  name='landsize'
                  register={register}
                  errors={errors}
                  placeholder='Input product'
                  validation={{ required: 'Landsize is required' }}
                />
                <SelectInput
                  control={control}
                  Controller={Controller}
                  data={resourcesquestion}
                  title='Do you have any available agricultural resources or equipment?'
                  name='agricresources'
                  placeholder='Select answer'
                />
                {hasResources === 'Yes' && (
                  <>
                    <SingleTextInput
                      label='List your available resources or equipment'
                      name='resourceslist'
                      register={register}
                      errors={errors}
                      placeholder='Input product'
                      validation={{ required: 'Resource list is required' }}
                    />
                  </>
                )}

                <div className='w-full'>
                  <SingleTextArea
                    label='Any other additional information?'
                    name='info'
                    register={register}
                    validation={{ required: 'Information is required' }}
                    error={errors.info}
                  />
                </div>

                <GlobalButton
                  isFilled={yesValidInput}
                  title='Submit'
                  isLoading={isLoading}
                />
                <p className='login-text'>
                  Have an account?{' '}
                  <a href='/login' className='login-link'>
                    Log in
                  </a>
                </p>
              </div>
            )}
            {selectedAnswer === 'No' && (
              <div className='w-full space-y-6'>
                <SelectInput
                  control={control}
                  Controller={Controller}
                  data={resourcesquestion}
                  title='Do you have any available agricultural resources or equipment?'
                  name='resourcesquestion'
                  placeholder='Select answer'
                />
                <div className='w-full'>
                  <SingleTextArea
                    label='Any other additional information?'
                    name='information'
                    register={register}
                    validation={{ required: 'Information is required' }}
                    error={errors.information}
                  />
                </div>
                <GlobalButton
                  isFilled={noValidInput}
                  title='Submit'
                  isLoading={isLoading}
                />
                <p className='login-text'>
                  Have an account?{' '}
                  <a href='/login' className='login-link'>
                    Log in
                  </a>
                </p>
              </div>
            )}
          </form>
        </>
      )}
    </div>
  )
}

export default EnterpriseExisitingLandForm
