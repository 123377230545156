import { ArrowLeft } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import useBackendService from '../../../../../config/services/backend-service'
import GlobalLoader from '../../../SharedComponents/GlobalLoader/GlobalLoader'
import './NewsDescriptionPage.css'

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const options = { day: '2-digit', month: 'long', year: 'numeric' }
  return date.toLocaleDateString('en-GB', options)
}

const NewsDescriptionPage = ({ unlock = false }) => {
  const { id } = useParams()
  const [article, setArticle] = useState(null)
  const [moreNews, setMoreNews] = useState([])
  const { mutate: fetchNews, isLoading } = useBackendService(
    '/articles?status=approved',
    'get',
    {
      onSuccess: (response) => {
        setMoreNews(response.data)
      },
      onError: (error) => {
        console.error('Failed to fetch more news:', error)
      }
    }
  )

  const { mutate: fetchNewsOne, isLoading: newsLoader } = useBackendService(
    `/articles/${id}`,
    'get',
    {
      onSuccess: (response) => {
        setArticle(response.data)
      },
      onError: (error) => {
        console.error('Failed to fetch the article:', error)
      }
    }
  )

  useEffect(() => {
    fetchNews()
    if (id) {
      fetchNewsOne()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (isLoading || newsLoader) {
    return <GlobalLoader />
  }
  if (!article && !moreNews) {
    return <GlobalLoader />
  }

  return (
    <>
      {article && moreNews
        ? (
          <div className='w-full h-full p-[2%] bg-white'>
            {unlock && (
              <div className='mb-6'>
                <h1 className='text-3xl text-darkGreen font-bold'>Agri news</h1>
                <p className='text-sm text-gray-600'>
                  Keep up with the latest happenings in the agribusiness world
                </p>
              </div>
            )}
            <div className='w-[5%]'>
              {unlock
                ? (
                  <a href='/market/agrinews'>
                    <ArrowLeft />
                  </a>
                  )
                : (
                  <a href='/agrinews'>
                    <ArrowLeft />
                  </a>
                  )}
            </div>
            <div className='w-full flex justify-center items-start lg:flex-row flex-col gap-2 mt-4'>
              <div className='w-full lg:w-[70%] h-full space-y-4'>
                <h1 className='text-black font-semibold'>{article.title}</h1>
                <div className='w-full'>
                  <img
                    src={article.image_url}
                    alt='Article'
                    className='object-cover'
                  />
                </div>
                <div className='w-full flex gap-2 justify-start items-center ts'>
                  <div className='circle' />
                  {formatDate(article.created_at)}
                </div>
                <p className='ts'>{article.body}</p>
              </div>
              <div className='w-full lg:w-[30%] h-full'>
                <h1 className='text-black font-semibold'>More news</h1>
                <div className='mt-4 w-full space-y-3'>
                  {moreNews
                    .filter((item) => item.id !== id)
                    .map((item, index) => (
                      <Link
                        to={`/agrinews/${item.id}`}
                        className='w-full rounded-md bg-[#f9f9f9] p-2 flex gap-2 justify-start items-center cursor-pointer'
                        key={index}
                      >
                        <img
                          src={item.image_url}
                          alt='Article'
                          className='object-cover w-[100px]'
                        />
                        <div className='flex flex-col justify-start items-start gap-1 ts'>
                          <h1 className='text-red-500 capitalize'>
                            {item.category}🔥
                          </h1>
                          <p>{item.title}</p>
                          <div className='w-full flex gap-2 justify-start items-center ts'>
                            <div className='circle' />
                            {formatDate(item.created_at)}
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>

              </div>
            </div>
          </div>
          )
        : (
          <GlobalLoader />
          )}
    </>
  )
}

export default NewsDescriptionPage
