import { BellDot, Menu } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import NotificationDrawer from '../../SharedComponents/NotificationDrawer/NotificationDrawer'
import useWindowSize from '../UseWindowSize/UseWindowSize'
import { getMessaging, onMessage } from 'firebase/messaging'
import useBackendService from '../../../../config/services/backend-service'
import { useAuth } from '../../../../config/contexts/authProvider'

const TopNav = ({ onClick, location, menus }) => {
  const { pathname } = location
  const { width } = useWindowSize()
  const isMobile = width < 768

  const getNavData = () => {
    for (const menu of menus) {
      if (pathname.startsWith(menu.link)) {
        return menu
      }

      if (menu.submenu) {
        for (const subMenu of menu.submenu) {
          if (pathname.startsWith(subMenu.link)) {
            return subMenu
          }
        }
      }
    }

    return null
  }
  const { user } = useAuth()
  const [notifications, setNotifications] = useState([])

  const endpoint =
    user.plan === 'Admin' ? '/notifications/admin' : `/notifications/${user.id}`
  const { mutate } = useBackendService(endpoint, 'get', {
    onSuccess: (response) => {
      setNotifications(response.data)
    },
    onError: (error) => {
      console.log(error)
    }
  })

  useEffect(() => {
    if (!user?.id) return

    mutate()
    const messaging = getMessaging()
    onMessage(messaging, (payload) => {
      const newNotification = {
        id: payload.data.id,
        senderId: payload.data.sender_id,
        recipientId: payload.data.recipient_id,
        content: payload.data.content,
        type: payload.data.type,
        createdAt: new Date(payload.data.created_at),
        updatedAt: new Date(payload.data.updated_at),
        read: payload.data.read === 'true',
        clickUrl: payload.data.click_url
      }

      setNotifications((prevNotifications) => [
        newNotification,
        ...prevNotifications
      ])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutate])
  const unreadCount = notifications.filter(item => item.read === false).length
  const countShow = unreadCount && unreadCount > 10 ? '+10' : unreadCount
  const navdata = getNavData()
  const { link, name } = navdata || {}
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const getDescription = () => {
    switch (name) {
      case 'Calendar':
        return 'Effortlessly manage schedules and track important events. The Calendar helps you organize planting seasons, harvest timelines, market days, and business appointments for seamless operations.'
      case 'Businesses':
        return 'Maintain comprehensive records of all your business branches. Create distinct entities for each branch, register staff, and efficiently manage operations across your entire network.'
      case 'Products':
        return 'Easily list and organize your agricultural products. The Products page helps you maintain an up-to-date inventory, display detailed product information, and track stock levels seamlessly.'
      case 'Stock':
        return 'Monitor and manage stock levels across locations. Stay on top of supply shortages or surpluses to keep your operations running smoothly and avoid interruptions.'
      case 'Staffs':
        return 'Organize staff roles, assign tasks, and track performance. Empower your team with the tools they need to work efficiently and achieve business goals.'
      case 'Activities':
        return 'Oversee all operational processes, from planting to distribution reports. Optimize workflows, track milestones, and manage day-to-day activities for maximum productivity.'
      case 'Catalogue':
        return 'This section provides a complete list of all the agricultural products managed within TGAIMS. Each product entry includes key details like categories, sourcing information, and other market information.'
      case 'agri-news':
        return 'Access the latest updates and trends in agriculture. From market reports to policy changes, Agri News keeps you informed to make smarter decisions for your business.'
      case 'Sales':
        return 'Access detailed sales information for all products within the TGAIMS-managed catalog. This feature offers insights into product performance, revenue trends, and sales metrics, empowering you to make informed decisions and optimize your business strategies.'
      case 'Map':
        return 'Gain a comprehensive view of agricultural locations, distribution networks, and logistics. The Map feature provides an intuitive interface to access and manage all agribusiness-related information, ensuring efficient decision-making.'
      case 'Invest':
        return 'Invest in agribusinesses and earn profitable returns over a fixed term. This feature allows individuals to grow their wealth while supporting sustainable agri businesses. Monitor your investments and returns with a transparent and user-friendly system designed to benefit both investors and agribusinesses.'
      default:
        return 'Manage your agribusiness efficiently'
    }
  }

  return (
    <div className='topnav-container'>
      {link && isMobile && (
        // For small screen devices (mobile)
        <div className='flex flex-col gap-0'>
          <p className='text-black font-[600] text-[18px]'>Hello, welcome👋</p>
          <p className='text-[#444] font-[400] text-[12px]'>
            Manage your agribusiness efficiently
          </p>
        </div>
      )}
      {link && !isMobile && (
        <div className='flex flex-col gap-0 w-full'>
          <p className='font-[600] text-[20px] text-darkGreen'>{name}</p>
          <p className='text-darkGreen font-[400] text-[12px] w-full'>
            {getDescription()}
          </p>
        </div>
      )}

      <div className='topnav-content-style'>
        <div className='center hidden lg:flex gap-2 text-gray-500'>
          <div className='bg-[#F6F6F6] text-darkGreen rounded-full h-[40px] w-[40px] center cursor-pointer relative' onClick={toggleDrawer}>
            <BellDot size={20} />
            {unreadCount > 0 && (
              <div className='bg-darkGreen text-white center absolute w-[28px] h-[28px] text-[12px] font-[600] rounded-full top-[-10px] right-[-5px]'>
                {countShow}
              </div>
            )}
          </div>
        </div>
        {link && (
          <div
            className='flex lg:hidden justify-end md:bg-primary md:p-3 md:rounded-full'
            onClick={onClick}
          >
            <Menu size={24} />
          </div>
        )}
        <NotificationDrawer
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
        />
      </div>
    </div>
  )
}

export default TopNav
