import { CloudUpload, X } from 'lucide-react'
import React, { useRef } from 'react'
import { resizeAndCompressImage } from '../../../../config/services/file-resize'
import pdf from '../../../resources/pdf.png'

function ImageUpload ({ name, images, setImages, oneimageneeded, allowPdf }) {
  const inputRef = useRef(null)

  const selectFiles = () => {
    inputRef.current.click()
  }

  const handleImageProcessing = async (file) => {
    try {
      if (allowPdf) {
        // Directly set the file (both images and PDFs) in the state
        const fileUrl = URL.createObjectURL(file)
        setImages((prevImages) => [
          ...prevImages,
          {
            name: file.name,
            url: fileUrl,
            file // Pass file directly
          }
        ])
      } else {
        // Process and compress images if allowPdf is false
        const compressedImage = await resizeAndCompressImage(file)
        const imageUrl = URL.createObjectURL(compressedImage)
        setImages((prevImages) => [
          ...prevImages,
          {
            name: compressedImage.name,
            url: imageUrl,
            file: compressedImage
          }
        ])
      }
    } catch (error) {
      console.error('Error processing the file:', error)
    }
  }

  const onFilesSelect = async (event) => {
    const files = event.target.files
    if (files.length === 0) return
    for (let i = 0; i < files.length; i++) {
      const fileType = files[i].type.split('/')[0]
      const isAllowed = allowPdf
        ? fileType === 'image' || files[i].type === 'application/pdf'
        : fileType === 'image'

      if (!isAllowed || images.some((e) => e.name === files[i].name)) continue

      await handleImageProcessing(files[i])
    }
  }

  const onDragOver = (event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'copy'
  }

  const onDragLeave = (event) => {
    event.preventDefault()
  }

  const onDrop = (event) => {
    event.preventDefault()
    const files = event.dataTransfer.files
    for (let i = 0; i < files.length; i++) {
      const fileType = files[i].type.split('/')[0]
      const isAllowed = allowPdf
        ? fileType === 'image' || files[i].type === 'application/pdf'
        : fileType === 'image'

      if (!isAllowed || images.some((e) => e.name === files[i].name)) continue

      handleImageProcessing(files[i])
    }
  }

  const deleteImage = (index) => {
    setImages((previousImages) => previousImages.filter((_, i) => i !== index))
  }

  return (
    <div className='flex flex-col gap-4 lg:gap-8'>
      <div
        className='w-full center flex-col rounded-md border-dashed border ts h-full gap-2 p-4'
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <CloudUpload className='text-black rounded-full bg-[#F0F2F5] w-[50px] h-[50px]' />
        <h1 className='text-[#98A2B3]'>
          <span className='text-darkGreen cursor-pointer font-semibold'>
            Click to upload
          </span>{' '}
          or drag and drop
        </h1>
        <h1 className='text-[#98A2B3]'>
          SVG, PNG, JPG {allowPdf && ', PDF'} (max. 800x400px)
        </h1>
        <div className='center gap-2 w-full text-[#98A2B3]'>
          <div className='w-[50%] lg:w-[40%] bg-[#F0F2F5] h-[1px]' />
          OR
          <div className='w-[50%] lg:w-[40%] bg-[#F0F2F5] h-[1px]' />
        </div>
        <button
          type='button'
          className='bg-darkGreen text-white ts w-[200px] rounded-md mx-auto h-[50px]'
          onClick={selectFiles}
        >
          Browse Files
        </button>
        <input
          type='file'
          accept={`.svg, .png, .jpg, .jpeg${allowPdf ? ', .pdf' : ''}`}
          hidden
          name={name}
          multiple
          ref={inputRef}
          onChange={onFilesSelect}
        />
      </div>
      {oneimageneeded
        ? (
          <div className='flex flex-col items-start justify-center '>
            {images.length > 0 && (
              <div className='space-y-2'>
                {images.map((image, index) => (
                  <div
                    key={index}
                    className='relative w-full bg-white flex justify-between items-start p-2 rounded-md border'
                  >
                    <img
                      src={
                      image.file.type === 'application/pdf'
                        ? pdf
                        : image.url || image
                    }
                      alt={image.name}
                      className='w-[60px] h-[60px] object-cover mr-4'
                    />
                    <div className='w-[80%] center space-y-2 flex-col flex-grow'>
                      <div className='w-full flex justify-between items-center text-sm'>
                        <h1 className='truncate'>{image.name}</h1>
                        <div
                          className='bg-secondary rounded-full size-4 flex items-center justify-center cursor-pointer'
                          onClick={() => deleteImage(index)}
                        >
                          <X className='text-darkGreen' size={12} />
                        </div>
                      </div>
                      <div className='w-full h-[5px] bg-darkGreen rounded-full' />
                      <div className='w-full flex justify-between items-center text-sm'>
                        <h1>Uploaded</h1>
                        <h1>{(image.file.size / (1024 * 1024)).toFixed(2)} mb</h1>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          )
        : (
          <div className='flex flex-row items-center gap-4 lg:gap-2'>
            {images.map((image, index) => (
              <div
                className='size-[120px] relative rounded-lg overflow-hidden'
                key={index}
              >
                <div
                  className='flex absolute bg-secondary top-[1px] right-[1px] rounded-full size-4 items-center justify-center cursor-pointer'
                  onClick={() => deleteImage(index)}
                >
                  <X className='text-darkGreen' size={12} />
                </div>
                <img
                  src={image.url || image}
                  alt={image.name}
                  className='w-full h-full object-cover'
                />
              </div>
            ))}
          </div>
          )}
      <div className='flex items-center gap-1'>
        {!oneimageneeded && (
          <>
            {images.length < 4 && (
              <p
                className={`font-[500] text-[12px] lg:text-[12px] ${
                  images.length === 0 ? 'text-grey' : 'text-[#e35b67]'
                }`}
              >
                Upload {images.length === 0 ? 4 : 4 - images.length} more images
                to continue
              </p>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ImageUpload
