import React, { useState } from 'react'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import Accordion from '../../../SharedComponents/Accordion/Accordion'
import DynamicTextArea from '../../../SharedComponents/TextAreaInput/ProductDetailsTextArea'

function Sales ({ register, errors, user, marketPrices }) {
  const [textAreas, setTextAreas] = useState([{ value: '', date: '' }])
  return (
    <form action='' className=' flex flex-col gap-5 lg:gap-10 max-w-fit  '>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='storageSolutions'
          register={register}
          errors={errors}
          text='Storage Solutions'
          disabled={user.role === 'approver'}
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='packagingMethods'
          register={register}
          errors={errors}
          text='Packaging Methods'
          disabled={user.role === 'approver'}
        />
      </div>

      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='logisticsProviders'
          register={register}
          errors={errors}
          text='Logistics Providers'
          disabled={user.role === 'approver'}
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='salesChannels'
          register={register}
          errors={errors}
          text='Sales Channels'
          disabled={user.role === 'approver'}
        />
      </div>
      <div className=' w-full lg:w-[876px] border border-[#f2f2f2] '>
        <div className=' p-4 lg:p-8 border-b border-[#f2f2f2] '>
          <Accordion
            text='Market Prices'
            subtext='Enter a minimum of 5 market prices'
          />
        </div>
        <div className=' p-4 lg:p-8 '>

          <DynamicTextArea
            name='marketPrices'
            register={register}
            errors={errors}
            text='Market Prices'
            isMarketPrice
            nextbtn='Add Another Market Price'
            existingTextAreas={marketPrices}
            textAreas={textAreas}
            setTextAreas={setTextAreas}
          />
        </div>
      </div>
    </form>
  )
}

export default Sales
