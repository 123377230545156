import { BellDot, Menu } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { RegularDashboardContent } from '../../../data/static'
import NotificationDrawer from '../../SharedComponents/NotificationDrawer/NotificationDrawer'
import './NavBar.css'
import { getMessaging, onMessage } from 'firebase/messaging'
import useBackendService from '../../../../config/services/backend-service'
import { useAuth } from '../../../../config/contexts/authProvider'

const NavBar = ({ location, onClick }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const { pathname } = location
  const navData = RegularDashboardContent.find((item) => item.link === pathname)
  const { user } = useAuth()
  const [notifications, setNotifications] = useState([])

  const endpoint =
    user.plan === 'Admin' ? '/notifications/admin' : `/notifications/${user.id}`
  const { mutate } = useBackendService(endpoint, 'get', {
    onSuccess: (response) => {
      setNotifications(response.data)
    },
    onError: (error) => {
      console.log(error)
    }
  })

  useEffect(() => {
    if (!user?.id) return

    mutate()
    const messaging = getMessaging()
    onMessage(messaging, (payload) => {
      const newNotification = {
        id: payload.data.id,
        senderId: payload.data.sender_id,
        recipientId: payload.data.recipient_id,
        content: payload.data.content,
        type: payload.data.type,
        createdAt: new Date(payload.data.created_at),
        updatedAt: new Date(payload.data.updated_at),
        read: payload.data.read === 'true',
        clickUrl: payload.data.click_url
      }

      setNotifications((prevNotifications) => [
        newNotification,
        ...prevNotifications
      ])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutate])
  const unreadCount = notifications.filter(item => item.read === false).length
  const countShow = unreadCount && unreadCount > 10 ? '+10' : unreadCount

  const { text, subtext } = navData || {}
  return (
    <div className='pro-topnav-container'>
      <div className='flex flex-col gap-3 lg:gap-[15px] min-w-fit'>
        <p className=' text-black lg:text-darkgreen font-[700] lg text-[20px] lg:text-[22px] '>
          {text}
        </p>
        <p
          style={{ width: '70vw' }}
          className='text-[#444444] lg:text-darkgreen font-[400] text-[12px] '
        >
          {subtext}
        </p>
      </div>
      <div className='pronav-content-style'>
        <div className='center gap-2 text-gray-500'>
          <div className='bg-[#F6F6F6] text-darkGreen rounded-full h-[40px] w-[40px] center cursor-pointer relative' onClick={toggleDrawer}>
            <BellDot size={20} />
            {unreadCount > 0 && (
              <div className='bg-darkGreen text-white center absolute w-[28px] h-[28px] text-[12px] font-[600] rounded-full top-[-10px] right-[-5px]'>
                {countShow}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className=' flex lg:hidden ' onClick={onClick}>
        <Menu size={24} />
      </div>

      <NotificationDrawer
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
      />
    </div>
  )
}

export default NavBar
